// pure JS to collapse the navbar on scroll
$(function() {
    const navbarPlaceholder = document.querySelector('.navbar-placeholder');
    const navbar = navbarPlaceholder.querySelector('.navbar');

    if (navbarPlaceholder === null || navbar === null) return;

    const navbarPlaceholderHeight = navbarPlaceholder.clientHeight;
    navbarPlaceholder.style.height = navbarPlaceholderHeight + "px";

    let navbarPlaceholderOffset = navbarPlaceholder.offsetTop;

    window.onresize = function() {
        navbarPlaceholderOffset = navbarPlaceholder.offsetTop;
    };

    window.onscroll = function() {
        let windowPageOffset = window.pageYOffset;
        //console.log(windowPageOffset + ' / ' + navbarPlaceholderOffset);

        if (window.pageYOffset  >= navbarPlaceholderOffset) {
            navbar.classList.add("fixed-top")
        } else {
            navbar.classList.remove("fixed-top");
        }
    };
});



// jQuery to closes the dropdown menu on menu item click, resp. page-scroll
$(function() {
    let $pageScroll = $('.page-scroll');
    let $navToggler = $('.navbar-toggler:visible');

    $(window).resize(function() {
        $navToggler = $('.navbar-toggler:visible');
    })

    $pageScroll.bind('click', function() {
      if (!$navToggler.hasClass('collapsed')) {
        $navToggler.click();
      }
    });
});



// jQuery for page scrolling feature - requires jQuery Easing plugin
$(function() {
    let $page = $('html, body');

    $('a.page-scroll').bind('click', function() {
        let $anchor = $(this);
        let offset = $('.navbar-placeholder').outerHeight() || 0;

        $page.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", function() {
            $page.stop();
        });

        $page.stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top - offset
        }, 1500, 'easeInOutExpo', function() {
            $page.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove");
        });
        return false;
    });
});



// warning layer
$(function() {
    let $warningLayer = $('.warning__layer');
    let $warningClose = $warningLayer.find('.warning__close');

    // context menu desktop
    $(document).on('contextmenu', '.gallery__list-item-link, .fancybox__image', function(event) {
        $warningLayer.css('display', 'flex');
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
        return false;
    })

    // close
    $warningClose.bind('click', function() {
        $warningLayer.hide();
        return false;
    });
});
